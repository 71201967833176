<template>
	<router-view></router-view>
</template>

<script setup lang="ts">
import { isIOS, isAndroid, isMiniProgram } from '@/utils/utils'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

onMounted(() => {
	const { aplus_queue } = window
	// 集成应用的appKey
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['appKey', '63969d7c88ccdf4b7ea1ef25']
	});

	// 关闭自动pv
	aplus_queue.push({
		action: 'aplus.setMetaInfo',
		arguments: ['aplus-waiting', 'MAN']
	});

	if (isMiniProgram || (window.location && window.location.href.includes('tclx'))) {
		// console.log('小程序index')
	} else {
		if (isIOS || isAndroid) {
			router.replace('/m')
		} else {
			router.replace('/')
		}
	}
})
</script>

<style scoped lang="scss">
@import "@/utils/scss/var";

@font-face {
	font-family: HYRY;
	src: url(#{$font}/source.ttf);
}

.pace .pace-progress {
	background: #6275CE;
}
</style>
