
// type: Boolean
export const isIOS = (function () {
  const u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}())

// type: Boolean
export const isAndroid = (function () {
  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
}())

// type: Boolean
export const isWeixin = (function () {
  const u = navigator.userAgent.toLowerCase()
  return u.indexOf('micromessenger') !== -1
}())

// 判断是否小程序内
export const isMiniProgram = (function () {
	const u = navigator.userAgent.toLowerCase()
	return u.indexOf('miniprogram') !== -1
}())

/**
 * 移除 location.pathname 的最后一个 /
 * @param {*} pathname - location.pathname 的值
 * @returns 去除最后一个 / 后的 pathname
 */
export const getLocationPath = (pathname = window.location.href) => {
  return pathname.substring(0, pathname.lastIndexOf('/'))
}

/**
 * 上报埋点
 * @param {*} groupName
 * @param {*} eventName
 */
export function eventTrack (groupName: string, eventName: string) {
  if (window._czc) {
    window._czc.push(['_trackEvent', groupName, eventName])
  } else if (window._hmt) {
    window._hmt.push(['_trackEvent', groupName, eventName])
  }
}
