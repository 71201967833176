import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import "amfe-flexible"
import './assets/css/common.css'
import '@/utils/scss/reset.scss'
import { registerSW } from 'virtual:pwa-register'

registerSW()

const pinia = createPinia()

createApp(App)
    .use(router)
    .use(pinia)
    .mount('#app')
